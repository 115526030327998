import { Route, Switch, Redirect, useParams, useLocation } from "react-router-dom";
import React, { lazy } from "react";
import CDPpage from "./components/storefront/KnowledgeStreams/Pages/CDPpage.js";
const ProductRatingComponent = lazy(() => import("./components/mall/ProductRatingComponent"));
const ViewLocation = lazy(() => import("./components/storefront/ViewLocation"));
const EloAboutUs = lazy(() => import("./components/storefront/ELO/Pages/Aboutelo"));
const ELOThemeFAQ = lazy(() => import("./components/storefront/ELO/Pages/ELOThemeFAQ"));
const Aboutpage = lazy(() => import("./components/storefront/ThemeBook/Pages/Aboutpage"));
const Bookpreview = lazy(() => import("./components/storefront/ThemeBook/Pages/SeeBook"));
const SpringWishlist = lazy(() =>
    import("./components/storefront/ThemeSpringtide/ThemeSpringtideComponents/SpringWishlist")
);
const NoData = lazy(() => import("./components/mall/sub-pages/NoData"));
const SpringtideCategoriesDetails = lazy(() =>
    import("./components/storefront/ThemeSpringtide/Pages/SpringtideCategoriesDetails")
);
const CategoriesMobileComponent = lazy(() => import("./components/storefront/Telemart/CategoriesMobileComponent"));
const SpringtideServices = lazy(() => import("./components/storefront/ThemeSpringtide/Pages/SpringtideServices"));
const CosgenicAboutUs = lazy(() => import("./components/storefront/ThemeSpringtide/Pages/Aboutcosgenic"));
const OurStoryPaolas = lazy(() => import("./components/storefront/ThemeSpringtide/Pages/OurStoryPaolas"));
const SpringThemetestimonial = lazy(() =>
    import("./components/storefront/ThemeSpringtide/Pages/SpringThemetestimonial")
);
const SpringThemeContactus = lazy(() => import("./components/storefront/ThemeSpringtide/Pages/SpringThemeContactus"));
const SpringThemeBlogDetails = lazy(() =>
    import("./components/storefront/ThemeSpringtide/Pages/SpringThemeBlogDetails")
);
const SpringThemeBlog = lazy(() => import("./components/storefront/ThemeSpringtide/Pages/SpringThemeBlog"));
const SpringThemeFaq = lazy(() => import("./components/storefront/ThemeSpringtide/Pages/SpringThemeFaq"));
const AddSection = lazy(() => import("./components/storefront/Subcomponents/EditStore/AddSection"));
// ***********Routes End***********
const MyOrdersComponent = lazy(() => import("./components/mall/MyOrdersComponent"));
const MyAppointmentsComponent = lazy(() => import("./components/mall/MyAppointmentsComponent"));
const OrderStatusComponent = lazy(() => import("./components/mall/OrderStatusComponent"));
const AppointmentStatusComponent = lazy(() => import("./components/mall/AppointmentStatusComponent"));

const MyRatingComponent = lazy(() => import("./components/mall/MyRatingComponent"));
const AccountComponent = lazy(() => import("./components/mall/AccountComponent"));
const CombinedComponent = lazy(() => import("./components/mall/CombinedComponent"));
const WalletComponent = lazy(() => import("./components/mall/WalletComponent"));
const PagesComponent = lazy(() => import("./components/mall/PagesComponent"));
const ApplicationSubmitted = lazy(() => import("./components/mall/ApplicationSubmitted"));
const ApplyForInstallment = lazy(() => import("./components/mall/ApplyForInstallment"));

const CartComponent = lazy(() => import("./components/mall/CartComponent"));
const FeaturedProductComponent = lazy(() => import("./components/mall/FeaturedProductComponent"));
const CheckoutComponent = lazy(() => import("./components/mall/CheckoutComponent"));
const MainComponent = lazy(() => import("./components/storefront/MainComponent/index.js"));
const EditMainComponent = lazy(() => import("./components/storefront/EditMainComponent"));

const SmashFeeds = lazy(() => import("./components/storefront/SmashBurger/SmashComponents/Smashfeedcontent"));

const OrderConfirmComponent = lazy(() => import("./components/mall/OrderConfirmComponent"));
const ShopRating = lazy(() => import("./components/mall/ShopRating"));
const ProductDetailSFComponent = lazy(() => import("./components/storefront/ProductDetailSFComponent"));
// Payment Success
const PaymentSuccess = lazy(() => import("./components/storefront/UI/PaymentSuccess"));
const ContactComponent = lazy(() => import("./components/storefront/ContactComponent"));
const PageComponent = lazy(() => import("./components/storefront/PageComponent"));
const SmashMenu = lazy(() => import("./components/storefront/SmashBurger/SmashComponents/Smashmenucontent"));

//Knowledge Stream
const CreateaccountStepone = lazy(() => import("./components/storefront/KnowledgeStreams/Pages/CreateAccountpage"));
const Homepage = lazy(() => import("./components/storefront/KnowledgeStreams/Pages/HomePage"));
const ViewApplicationpage = lazy(() => import("./components/storefront/KnowledgeStreams/Pages/ViewApplicationpage"));
const ExecutiveTeampage = lazy(() => import("./components/storefront/KnowledgeStreams/Pages/ExecutiveTeamPage/index"));
const KnowledgePartnersPage = lazy(() =>
    import("./components/storefront/KnowledgeStreams/Pages/KnowledgePartnersPage")
);
//Knowledge Stream New
const TestmonialPage = lazy(() =>
    import("./components/storefront/KnowledgeStreams/Pages/KnowledeStreamsNew/Pages/TestmonialPage")
);

const KSEventsPage = lazy(() =>
    import("./components/storefront/KnowledgeStreams/Pages/KnowledeStreamsNew/Pages/KSEventspage")
);
const KSEventsDeatilsPage = lazy(() =>
    import("./components/storefront/KnowledgeStreams/Pages/KnowledeStreamsNew/Pages/KSEventsDeatilsPage")
);

const ExpoGallery = lazy(() =>
    import("./components/storefront/KnowledgeStreams/Pages/KnowledeStreamsNew/Pages/ExpoGallery")
);

const KsBlogpage = lazy(() =>
    import("./components/storefront/KnowledgeStreams/Pages/KnowledeStreamsNew/Pages/KSBlogpage")
);

const KSBlogDetails = lazy(() =>
    import("./components/storefront/KnowledgeStreams/Pages/KnowledeStreamsNew/Pages/KSBlogDetails")
);

const UniversityAlliancesPage = lazy(() =>
    import("./components/storefront/KnowledgeStreams/Pages/UniversityAlliancesPage")
);
const KnowledgeTestimonialsPage = lazy(() =>
    import("./components/storefront/KnowledgeStreams/Pages/KnowledgeTestimonialsPage/index")
);
const TechnicalProgrammespage = lazy(() =>
    import("./components/storefront/KnowledgeStreams/Pages/TechnicalProgrammespage")
);
const KnowledgeProfilepage = lazy(() => import("./components/storefront/KnowledgeStreams/Pages/KnowledgeProfilepage"));

const TechnicalProgrammesDetailspage = lazy(() =>
    import("./components/storefront/KnowledgeStreams/Pages/TechnicalProgrammesDetailspage")
);
const Softskillpage = lazy(() => import("./components/storefront/KnowledgeStreams/Pages/Softskillpage"));
const SAPpage = lazy(() => import("./components/storefront/KnowledgeStreams/Pages/SAPpage"));
const GettingStartedpage = lazy(() =>
    import("./components/storefront/KnowledgeStreams/Pages/GettingStartedPage/index")
);
const NewsandEventspage = lazy(() => import("./components/storefront/KnowledgeStreams/Pages/NewsandEventspage"));
const NewsandEventsDetailspage = lazy(() =>
    import("./components/storefront/KnowledgeStreams/Pages/NewsandEventsDetailspage")
);
const KSContactspage = lazy(() => import("./components/storefront/KnowledgeStreams/Pages/ContactUs"));
const KSFaqpage = lazy(() => import("./components/storefront/KnowledgeStreams/Pages/FaqPage/index"));
const CosaCommingSoon = lazy(() => import("./components/storefront/CosaNostra/CosaNostraThemeComponents/ComingSoon"));
const SpringThemeGuest = lazy(() => import("./components/storefront/ThemeSpringtide/Pages/SpringThemeGuest"));
const SpringThemeSignup = lazy(() => import("./components/storefront/ThemeSpringtide/Pages/SpringThemeSignup"));
const ThemeReturnsPolicies = lazy(() => import("./components/storefront/ThemeSpringtide/Pages/ThemeReturnsPolicies"));

function StorefrontRoutes() {
    return (
        <>
            <Switch>
                <Route exact path="/" component={MainComponent}></Route>
                <Route exact path="/demo/" component={MainComponent}></Route>
                <Route exact path="/beta/" component={MainComponent}></Route>
                <Route exact path="/demo" component={MainComponent}></Route>
                <Route exact path="/beta" component={MainComponent}></Route>
                <Route exact path="/login" component={SpringThemeGuest} />
                <Route exact path="/signup" component={SpringThemeSignup} />
                <Route exact path="/returns-shipping-policies" component={ThemeReturnsPolicies} />
                <Route exact path="/branch*" component={MainComponent}></Route>
                <Route exact path="/contact" component={ContactComponent} />
                <Route exact path="/comming-soon" component={CosaCommingSoon} />
                <Route exact path="/paolas-story" component={OurStoryPaolas} />
                <Route exact path="/product-detail/:id" component={ProductDetailSFComponent} />
                <Route exact path="/paymentsuccess/:data" component={PaymentSuccess} />
                <Route exact path="/products/:id" component={ProductDetailSFComponent} />
                <Route exact path="/preview" component={Bookpreview} />
                <Route exact path="/order-confirm/:order_id" component={OrderConfirmComponent}></Route>
                <Route exact path="/editStore/add-new-section/:token" component={AddSection}></Route>
                <Route exact path="/view-location" component={ViewLocation}></Route>
                <Route exact path="/cart-detail" component={CartComponent}></Route>
                <Route exact path="/view-all/:type" component={FeaturedProductComponent}></Route>
                <Route exact path="/checkout" component={CheckoutComponent}></Route>
                <Route exact path="/order-details" component={MyOrdersComponent}></Route>
                <Route exact path="/my-appointments" component={MyAppointmentsComponent}></Route>
                <Route exact path="/order-status/:order_id/:storeid" component={OrderStatusComponent}></Route>
                <Route exact path="/testimonials" component={TestmonialPage}></Route>
                <Route exact path="/ksEvents" component={KSEventsPage}></Route>
                <Route exact path="/ksEvents/:id/*" component={KSEventsDeatilsPage}></Route>
                <Route exact path="/expoGallery/:id" component={ExpoGallery}></Route>
                <Route exact path="/ksBlogs" component={KsBlogpage}></Route>
                <Route exact path="/ksBlogDetails/:id" component={KSBlogDetails}></Route>
                <Route
                    exact
                    path="/appointment-status/:order_id/:storeid"
                    component={AppointmentStatusComponent}
                ></Route>

                <Route exact path="/order-rating/:order_id/:storeid" component={ProductRatingComponent}></Route>

                <Route exact path="/reviews-detail" component={MyRatingComponent}></Route>
                <Route exact path="/my-account" component={AccountComponent}></Route>

                <Route
                    exact
                    path="/combined-view/:token/:storeid/:storetoken/:showActive"
                    component={CombinedComponent}
                ></Route>
                <Route
                    exact
                    path="/combined-view/:token/:storeid/:storetoken/:showActive/*"
                    component={CombinedComponent}
                ></Route>
                <Route exact path="/my-wallet" component={WalletComponent}></Route>
                <Route exact path="/pages/*" component={PagesComponent}></Route>

                <Route exact path="/shop" component={SpringtideCategoriesDetails}></Route>
                <Route exact path="/shop/:categories" component={SpringtideCategoriesDetails}></Route>
                <Route exact path="/mobile-categories" component={CategoriesMobileComponent}></Route>
                <Route exact path="/services" component={SpringtideServices}></Route>

                <Route exact path="/testimonials" component={SpringThemetestimonial}></Route>

                <Route exact path="/wishlist" component={SpringWishlist}></Route>

                <Route exact path="/blog" component={SpringThemeBlog}></Route>
                <Route exact path="/blog/:id" component={SpringThemeBlogDetails}></Route>
                <Route exact path="/page/:id/:slug" component={PageComponent}></Route>

                <Route exact path="/store-rating" component={ShopRating}></Route>
                <Route exact path="/menu" component={SmashMenu}></Route>

                <Route exact path="/apply-for-installment" component={ApplyForInstallment}></Route>
                <Route exact path="/application-submitted" component={ApplicationSubmitted}></Route>
                <Route exact path="/smashfeeds" component={SmashFeeds}></Route>

                {/*KS*/}

                <Route exact path="/applynow" component={CreateaccountStepone}></Route>
                <Route exact path="/applynow/cdp/:cid" component={CreateaccountStepone}></Route>
                <Route exact path="/applynow/:id/edit" component={CreateaccountStepone}></Route>

                <Route
                    exact
                    path="/knowledgehome"
                    render={(props) => <DashboardRedirect {...props} oldURL="knowledgehome" />}
                />
                <Route exact path="/profile" component={KnowledgeProfilepage} />
                <Route exact path="/viewapplication/:id" component={ViewApplicationpage} />
                <Route
                    exact
                    path="/executiveteam"
                    render={(props) => <DashboardRedirect {...props} oldURL="executiveteam" />}
                />
                <Route
                    exact
                    path="/knowledgepartner"
                    render={(props) => <DashboardRedirect {...props} oldURL="knowledgepartner" />}
                />
                <Route exact path="/university" component={UniversityAlliancesPage} />
                <Route
                    exact
                    path="/technicalProgrammesDetails"
                    render={(props) => <DashboardRedirect {...props} oldURL="technicalProgrammesDetails" />}
                />
                <Route
                    exact
                    path="/programmesDetails/:name/:id"
                    render={(props) => <DashboardRedirect {...props} oldURL="programmesDetails" />}
                />
                <Route
                    exact
                    path="/softskill"
                    render={(props) => <DashboardRedirect {...props} oldURL="softskill" />}
                />
                <Route exact path="/sap" component={SAPpage} />
                <Route exact path="/cdp" component={CDPpage} />
                <Route
                    exact
                    path="/gettingstarted"
                    render={(props) => <DashboardRedirect {...props} oldURL="gettingstarted" />}
                />
                <Route exact path="/newspage" render={(props) => <DashboardRedirect {...props} oldURL="newspage" />} />
                <Route
                    exact
                    path="/newspagedetails"
                    render={(props) => <DashboardRedirect {...props} oldURL="newspagedetail" />}
                />
                <Route
                    exact
                    path="/knowledgeTestimonials"
                    render={(props) => <DashboardRedirect {...props} oldURL="knowledgeTestimonials" />}
                />
                <Route
                    exact
                    path="/technicalProgrammes"
                    render={(props) => <DashboardRedirect {...props} oldURL="technicalProgrammes" />}
                />

                {/*KS - Revised URls*/}

                <Route exact path="/executive-team" component={ExecutiveTeampage} />
                <Route exact path="/knowledge-partner" component={KnowledgePartnersPage} />
                <Route exact path="/technical-programmes-details" component={TechnicalProgrammesDetailspage} />
                <Route exact path="/programmes-details/:name/:id" component={TechnicalProgrammesDetailspage} />
                <Route exact path="/soft-skill" component={Softskillpage} />
                <Route exact path="/getting-started" component={GettingStartedpage} />
                <Route exact path="/news-page" component={NewsandEventspage} />
                <Route exact path="/news-page-details" component={NewsandEventsDetailspage} />
                <Route exact path="/knowledge-testimonials" component={KnowledgeTestimonialsPage} />
                <Route
                    exact
                    path="/technical-programmes"
                    render={(props) => <DashboardRedirect {...props} oldURL="technical-Programmes" />}
                />
                <Route exact path="/programmes/:category/:id" component={TechnicalProgrammespage} />
                <Route
                    exact
                    path="/contact-us"
                    component={parseInt(window.$storeId) === 841705 ? KSContactspage : SpringThemeContactus}
                />
                {/* {renderConditionalRoutes()} */}
                <Route
                    exact
                    path="/faq"
                    component={
                        parseInt(window.$storeId) === 726085
                            ? ELOThemeFAQ
                            : parseInt(window.$storeId) === 841705
                            ? KSFaqpage
                            : SpringThemeFaq
                    }
                />
                <Route
                    exact
                    path="/aboutus"
                    component={
                        parseInt(window.$storeId) === 726085
                            ? EloAboutUs
                            : parseInt(window.$storeId) === 813545
                            ? CosgenicAboutUs
                            : Aboutpage
                    }
                />
                <Route exact path="/requestquoat" component={SpringThemeContactus} />
                <Route
                    exact
                    path="/contactus"
                    component={
                        parseInt(window.$storeId) === 841705
                            ? KSContactspage
                            : parseInt(window.$storeId) === 813545
                            ? SpringThemeContactus
                            : SpringThemeContactus
                    }
                />
                {/* <Route render={() => <MainComponent />} /> */}
                <Route path="*" component={NoData} />
            </Switch>
        </>
    );
}

// Define the DashboardRedirect component
const DashboardRedirect = ({ oldURL }) => {
    // Add debugger statement for debugging purposes
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const query = Object.fromEntries(queryParams.entries());

    // Define the URL mapping
    const urlJson = {
        knowledgehome: "/",
        executiveteam: "/executive-team",
        knowledgepartner: "/knowledge-partner",
        technicalProgrammesDetails: "/technical-programmes-details",
        programmesDetails: "/programmes-details",
        softskill: "/soft-skill",
        gettingstarted: "/getting-started",
        newspage: "/news-page",
        newspagedetails: "/news-page-details",
        knowledgeTestimonials: "/knowledge-testimonials",
        "technicalProgrammes?cid=7": "/programmes/Tech-Launchpad/7",
        "technicalProgrammes?cid=3": "/programmes/Data-Sciences/3",
        "technicalProgrammes?cid=5": "/programmes/Cyber-Security/5",
        "technicalProgrammes?cid=1": "/programmes/Web-Development/1",
        "technical-Programmes?cid=7": "/programmes/Tech-Launchpad/7",
        "technical-Programmes?cid=3": "/programmes/Data-Sciences/3",
        "technical-Programmes?cid=5": "/programmes/Cyber-Security/5",
        "technical-Programmes?cid=1": "/programmes/Web-Development/1",
    };
    // Check if the oldURL exists in the urlJson object
    // oldURL = window.location.pathname;
    const queryString = new URLSearchParams(query).toString();
    if (queryString) {
        oldURL = `${oldURL}?${queryString}`;
    }

    if (urlJson[oldURL]) {
        // Get the corresponding URL
        let url = urlJson[oldURL];
        // Redirect to the corresponding URL
        // const queryString = new URLSearchParams(query).toString();
        const redirectUrl = url;
        return <Redirect to={redirectUrl} />;
    }

    // Redirect to the home page if oldURL does not exist in urlJson
    return <Redirect to="/" />;
};

export default StorefrontRoutes;
